<template>
  <section class="container">
    <picture>
      <source :srcset="webpBackgroundSrc" type="image/webp" />
      <img
        :src="backgroundSrc"
        @load="onBackgroundLoad"
        class="background"
        :alt="alt"
      />
    </picture>
    <slot />
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "LazyBackgroundLoad",
  props: {
    backgroundSrc: {
      type: String,
      required: true,
    },
    webpBackgroundSrc: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  setup() {
    const backgroundLoaded = ref(false);
    const onBackgroundLoad = () => {
      backgroundLoaded.value = true;
    };
    return {
      backgroundLoaded,
      onBackgroundLoad,
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
}

.background {
  display: block;
  width: 100vw;
  @include sm-and-up {
    border-radius: 16px;
  }
}
</style>
