
import { defineComponent, ref } from "vue";
import VueLogo from "@/assets/vue.svg";
import SvelteLogo from "@/assets/svelte.svg";
import HelloFriends from "@/components/organisms/HelloFriends.vue";
import LazyBackgroundLoad from "@/components/templates/LazyBackgroundLoad.vue";
import About from "@/components/organisms/About.vue";
import BlogRss from "@/components/organisms/BlogRss.vue";

import {
  Facebook,
  Instagram,
  Youtube,
  Github,
  Linkedin,
  React,
  LanguageTypescript,
  Graphql,
  Plus,
} from "mdue";
import { useOpenPage } from "@/use/user-agent";

export default defineComponent({
  name: "Home",
  components: {
    Facebook,
    Instagram,
    Youtube,
    Github,
    Linkedin,
    React,
    LanguageTypescript,
    Graphql,
    Plus,
    LazyBackgroundLoad,
    HelloFriends,
    About,
    BlogRss,
  },
  setup() {
    const startAnimation = ref(false);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const ashtangiBackgroundSrc = ref(require("@/assets/ashtangi.jpg"));
    const ashtangiPreviewBackgroundSrc = ref(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("@/assets/ashtangi.jpg")
    );
    const ashtangiWebpBackgroundSrc = ref(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("@/assets/ashtangi.webp")
    );
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const programmerBackgroundSrc = ref(require("@/assets/programmer.jpg"));
    const programmerPreviewBackgroundSrc = ref(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("@/assets/programmer.jpg")
    );
    const programmerWebpBackgroundSrc = ref(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("@/assets/programmer.webp")
    );
    const toFacebook = () => {
      useOpenPage({
        webLink: "https://www.facebook.com/michelle.lei.50",
        iOSLink: "fb://profile/1363827123",
      });
    };
    const toInstagram = () => {
      useOpenPage({
        webLink: "https://www.instagram.com/michelle_nolastname",
        iOSLink: "instagram://user?username=michelle_nolastname",
      });
    };
    const toYoutube = () => {
      window.open(
        "https://www.youtube.com/channel/UCWcPMDgTL7Lddrh8WH5W0sg",
        "_blank"
      );
    };
    const toGithub = () => {
      window.open("https://github.com/leimichelle", "_blank");
    };
    const toLinkedIn = () => {
      window.open("https://www.linkedin.com/in/michelle-lei-01", "_blank");
    };

    const toClubhouse = () => {
      window.open("https://www.joinclubhouse.com/@michelleleiii", "_blank");
    };

    const slideIn = () => {
      setTimeout(() => {
        startAnimation.value = true;
      }, 1500);
    };

    return {
      startAnimation,
      toFacebook,
      toInstagram,
      toYoutube,
      toGithub,
      toLinkedIn,
      toClubhouse,
      VueLogo,
      SvelteLogo,
      ashtangiBackgroundSrc,
      programmerBackgroundSrc,
      ashtangiPreviewBackgroundSrc,
      programmerPreviewBackgroundSrc,
      ashtangiWebpBackgroundSrc,
      programmerWebpBackgroundSrc,
      LazyBackgroundLoad,
      slideIn,
    };
  },
});
