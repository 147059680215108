
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "LazyBackgroundLoad",
  props: {
    backgroundSrc: {
      type: String,
      required: true,
    },
    webpBackgroundSrc: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  setup() {
    const backgroundLoaded = ref(false);
    const onBackgroundLoad = () => {
      backgroundLoaded.value = true;
    };
    return {
      backgroundLoaded,
      onBackgroundLoad,
    };
  },
});
