
import { defineComponent, ref } from "vue";
import Faq from "@/components/molecules/Faq.vue";
export default defineComponent({
  name: "Faqs",
  components: {
    Faq
  },
  setup() {
    const faqs = ref([
      {
        question: "What's your story?",
        answer:
          "100% made in Hong Kong. Raised in a free-range parenting environment. Went to Canada at the age of 16. Graduated from the University of Toronto with a Computer Engineering degree. Started my career in the land of the rising sun. I am still living in Japan at the moment, practicing Ashtanga before the work day starts."
      },
      {
        question: "What’s an ashtangi?",
        answer:
          "An ashtangi is someone who practices Ashtanga Yoga, which is a system of yoga brought to the modern world by K. Pattabhi Jois during the 20th century. Ashtanga is a Sanskrit compound that means “eight limbs of”, so Ashtanga yoga refers to the eight limbs of yoga defined by Patanjali in his Yoga Sutras."
      },
      {
        question: "How much money do you make in Japan?",
        answer:
          "As a full-time frontend engineer, I was making 5.8 million yen at HENNGE K.K., and am now making 10 millions at SmartNews, Inc. annually"
      },
      {
        question: "What’s your body made of?",
        answer: "Coffee, sugar and alcohol"
      }
    ]);
    return { faqs };
  }
});
