
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "HelloFriends",
  emits: ["fading"],
  setup(props, context) {
    const show = ref(true);
    onMounted(() => {
      setTimeout(() => {
        show.value = false;
        context.emit("fading");
      }, 2000);
    });
    return { show };
  }
});
