<template>
  <ul class="faqs">
    <faq
      v-for="(f, idx) in faqs"
      :key="`faq-${idx}`"
      :question="f.question"
      :answer="f.answer"
      :class="`faq-${idx}`"
    >
    </faq>
  </ul>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Faq from "@/components/molecules/Faq.vue";
export default defineComponent({
  name: "Faqs",
  components: {
    Faq
  },
  setup() {
    const faqs = ref([
      {
        question: "What's your story?",
        answer:
          "100% made in Hong Kong. Raised in a free-range parenting environment. Went to Canada at the age of 16. Graduated from the University of Toronto with a Computer Engineering degree. Started my career in the land of the rising sun. I am still living in Japan at the moment, practicing Ashtanga before the work day starts."
      },
      {
        question: "What’s an ashtangi?",
        answer:
          "An ashtangi is someone who practices Ashtanga Yoga, which is a system of yoga brought to the modern world by K. Pattabhi Jois during the 20th century. Ashtanga is a Sanskrit compound that means “eight limbs of”, so Ashtanga yoga refers to the eight limbs of yoga defined by Patanjali in his Yoga Sutras."
      },
      {
        question: "How much money do you make in Japan?",
        answer:
          "As a full-time frontend engineer, I was making 5.8 million yen at HENNGE K.K., and am now making 10 millions at SmartNews, Inc. annually"
      },
      {
        question: "What’s your body made of?",
        answer: "Coffee, sugar and alcohol"
      }
    ]);
    return { faqs };
  }
});
</script>

<style lang="scss" scoped>
.faqs {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  @media only screen and (min-width: 1280px) {
    justify-content: center;
  }
}

$box-shadow-position: 10px 16px 10px -4px;

.faq-0 {
  background: linear-gradient(-45deg, #ffc107, #ff7926);
  box-shadow: $box-shadow-position #ff7926;
}

.faq-1 {
  background: linear-gradient(-45deg, #eb3349, #f45c43);
  box-shadow: $box-shadow-position #f45c43;
}

.faq-2 {
  background: linear-gradient(-45deg, #a8ff78, #408a73);
  box-shadow: $box-shadow-position #408a73;
}

.faq-3 {
  background: linear-gradient(-45deg, #23a6d5, #23d5ab);
  box-shadow: $box-shadow-position #158c70;
}

.faq-4 {
  background: linear-gradient(-45deg, #f0f2f0, #000c40);
  box-shadow: $box-shadow-position #000c40;
}
</style>
