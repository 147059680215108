interface UseOpenPageProp {
  webLink: string;
  iOSLink: string;
}

const useOpenPage = ({ webLink, iOSLink }: UseOpenPageProp) => {
  const iOS = /(iPad|iPhone|iPod)/.test(navigator.userAgent);
  if (iOS) {
    const loadedAt = new Date().getTime();
    setTimeout(function() {
      if (new Date().getTime() - loadedAt < 2000)
        window.open(webLink, "_blank");
    }, 25);
    // Try launching the app using URL schemes
    window.location.href = iOSLink;
  } else {
    window.open(webLink, "_blank");
  }
};
export { useOpenPage };
