<template>
  <section class="about">
    <h2 class="section-title">About</h2>
    <faqs />
    <ArrowLeftRight class="drag-arrow" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ArrowLeftRight } from "mdue";
import Faqs from "@/components/organisms/Faqs.vue";
export default defineComponent({
  name: "About",
  components: {
    Faqs,
    ArrowLeftRight
  }
});
</script>

<style lang="scss" scoped>
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 16px;
}

.drag-arrow {
  font-size: 40px;
  color: #795203;
  margin: auto;
  @media only screen and (min-width: 1280px) {
    display: none;
  }
}
</style>
