<template>
  <li class="faq">
    <strong class="question">{{ question }}</strong>
    <div class="answer">
      <p>{{ answer }}</p>
      <slot />
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "About",
  props: {
    question: {
      type: String,
      required: true
    },
    answer: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Bebas+Neue");
$margin-bottom: 18px;
$margin: 8px;
.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25% 10% 25% 5%;
  min-width: 90vw;
  margin: 0 $margin $margin-bottom;
  padding: 9% 8%;
  color: white;

  &:first-child {
    margin: 0 $margin $margin-bottom 0;
  }

  &:last-child {
    margin: 0 0 $margin-bottom $margin;
  }

  @include sm-and-up {
    min-width: 320px;
    width: 320px;
    max-width: 90vw;
    padding: 27px 24px;
  }
}

.question {
  font-family: "Bebas Neue";
  font-size: 32px;
  text-shadow: 2px 2px #404040;
}

.answer {
  font-family: "Montserrat";
  font-size: 19px;
  margin: auto;
  text-align: center;
}
</style>
