
import { defineComponent } from "vue";
import { ArrowLeftRight } from "mdue";
import Faqs from "@/components/organisms/Faqs.vue";
export default defineComponent({
  name: "About",
  components: {
    Faqs,
    ArrowLeftRight
  }
});
