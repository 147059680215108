<template>
  <div :style="{ display: 'none' }" />
  <transition name="fade">
    <article v-if="show" class="hello-friends">
      <h1>Hi Friends!</h1>
    </article>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "HelloFriends",
  emits: ["fading"],
  setup(props, context) {
    const show = ref(true);
    onMounted(() => {
      setTimeout(() => {
        show.value = false;
        context.emit("fading");
      }, 2000);
    });
    return { show };
  }
});
</script>

<style lang="scss" scoped>
.hello-friends {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  backdrop-filter: blur(2px);
  z-index: 10;
  h1 {
    font-size: 14vw;
    background: -webkit-linear-gradient(#eee, #ff9800d6);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.fade-enter-active {
  transition: opacity 0.4s ease-out;
}

.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
