export interface BlogPost {
  title: string;
  pubDate: string;
  postHTML: string;
}

export class BlogRssParser {
  constructor(source: string) {
    this.source = source;
  }

  private source: string;

  getPosts(): Promise<BlogPost[]> {
    return fetch(this.source)
      .then(response => response.text())
      .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
      .then(data => {
        const items = data.getElementsByTagName("item");
        const item = items.item(0);
        if (item) {
          const descriptions = item.getElementsByTagName("description");
          const title = item.getElementsByTagName("title")?.item(0)
            ?.textContent;
          const pubDate = item.getElementsByTagName("pubDate")?.item(0)
            ?.textContent;
          const post = descriptions?.item(0);
          if (post && post.textContent && title && pubDate) {
            return [
              {
                title,
                pubDate,
                postHTML: post.textContent
              }
            ];
          }
        }
        return [];
      });
  }
}
