<template>
  <hello-friends @fading="slideIn" />
  <div class="flex flex-col items-center" v-bind="{ ...$attrs }">
    <div class="mobile">
      <LazyBackgroundLoad
        :backgroundSrc="ashtangiBackgroundSrc"
        :webp-background-src="ashtangiWebpBackgroundSrc"
        alt="profile picture as an ashtangi"
      >
        <transition name="slide">
          <div class="ashtangi-container" v-if="startAnimation">
            <div class="flex px-1 items-center">
              <Facebook
                class="icon"
                @click="toFacebook"
                alt="link to Facebook profile"
              />
              <Instagram
                class="icon"
                @click="toInstagram"
                alt="link to Instagram profile"
              />
              <Youtube
                class="icon"
                @click="toYoutube"
                alt="link to YouTube channel"
              />
            </div>
            <h2 class="ashtangi-title">Ashtangi By Dawn</h2>
          </div>
        </transition>
      </LazyBackgroundLoad>
      <LazyBackgroundLoad
        :backgroundSrc="programmerBackgroundSrc"
        :webp-background-src="programmerWebpBackgroundSrc"
        alt="profile picture as a programmer"
      >
        <transition name="slide">
          <div class="programmer-container" v-if="startAnimation">
            <h2>Frontend Engineer By Day</h2>
            <div class="flex px-1 items-center">
              <img
                :src="VueLogo"
                class="vuejs"
                width="41.7"
                height="36"
                alt="VueJs icon"
              />
              <Plus class="plus" alt="plus icon" />
              <img
                :src="SvelteLogo"
                class="svelte"
                width="30"
                height="36"
                alt="VueJs icon"
              />
              <Plus class="plus" alt="plus icon" />
              <React class="react" alt="React icon" />
              <Plus class="plus" alt="plus icon" />
              <LanguageTypescript class="typescript" alt="Typescript icon" />
              <Plus class="plus" alt="plus icon" />
              <Graphql class="graphql" alt="GraphQL icon" />
            </div>
            <div class="flex px-1 items-center">
              <Github
                class="icon"
                @click="toGithub"
                alt="link to Github page"
              />
              <Linkedin
                class="icon"
                @click="toLinkedIn"
                alt="link to LinkedIn profile"
              />
            </div>
          </div>
        </transition>
      </LazyBackgroundLoad>
      <about />
    </div>
    <div class="desktop">
      <h1 class="title">
        <span class="title--ashtangi">Ashtangi By Dawn</span><br />
        <span class="title--programmer">Frontend Engineer By Day</span>
      </h1>
      <section>
        <div class="flex">
          <LazyBackgroundLoad
            :backgroundSrc="ashtangiBackgroundSrc"
            :webpBackgroundSrc="ashtangiWebpBackgroundSrc"
            alt="profile picture as an ashtangi"
          ></LazyBackgroundLoad>
          <LazyBackgroundLoad
            :backgroundSrc="programmerBackgroundSrc"
            :webpBackgroundSrc="programmerWebpBackgroundSrc"
            alt="profile picture as a programmer"
          >
            <div
              class="flex pt-2 pl-4 items-center gradient-background absolute top-0"
            >
              <img :src="VueLogo" width="56.2" height="48" alt="VueJs icon" />
              <Plus class="plus" alt="plus icon" />
              <img :src="SvelteLogo" width="40" height="48" alt="Svelte icon" />
              <Plus class="plus" alt="plus icon" />
              <React class="react" alt="React icon" />
              <Plus class="plus" alt="plus icon" />
              <LanguageTypescript class="typescript" alt="Typescript icon" />
              <Plus class="plus" alt="plus icon" />
              <Graphql class="graphql" alt="GraphQL icon" />
            </div>
          </LazyBackgroundLoad>
        </div>
        <div class="flex">
          <div class="flex w-1/2 justify-center items-center">
            <Facebook class="icon" @click="toFacebook" />
            <Instagram class="icon" @click="toInstagram" />
            <Youtube class="icon" @click="toYoutube" />
          </div>
          <div class="flex w-1/2 justify-center items-center">
            <Github class="icon" @click="toGithub" />
            <Linkedin class="icon" @click="toLinkedIn" />
          </div>
        </div>
      </section>
    </div>
    <about class="desktop__about"></about>
    <section class="blog-feed"><blog-rss /></section>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import VueLogo from "@/assets/vue.svg";
import SvelteLogo from "@/assets/svelte.svg";
import HelloFriends from "@/components/organisms/HelloFriends.vue";
import LazyBackgroundLoad from "@/components/templates/LazyBackgroundLoad.vue";
import About from "@/components/organisms/About.vue";
import BlogRss from "@/components/organisms/BlogRss.vue";

import {
  Facebook,
  Instagram,
  Youtube,
  Github,
  Linkedin,
  React,
  LanguageTypescript,
  Graphql,
  Plus,
} from "mdue";
import { useOpenPage } from "@/use/user-agent";

export default defineComponent({
  name: "Home",
  components: {
    Facebook,
    Instagram,
    Youtube,
    Github,
    Linkedin,
    React,
    LanguageTypescript,
    Graphql,
    Plus,
    LazyBackgroundLoad,
    HelloFriends,
    About,
    BlogRss,
  },
  setup() {
    const startAnimation = ref(false);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const ashtangiBackgroundSrc = ref(require("@/assets/ashtangi.jpg"));
    const ashtangiPreviewBackgroundSrc = ref(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("@/assets/ashtangi.jpg")
    );
    const ashtangiWebpBackgroundSrc = ref(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("@/assets/ashtangi.webp")
    );
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const programmerBackgroundSrc = ref(require("@/assets/programmer.jpg"));
    const programmerPreviewBackgroundSrc = ref(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("@/assets/programmer.jpg")
    );
    const programmerWebpBackgroundSrc = ref(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("@/assets/programmer.webp")
    );
    const toFacebook = () => {
      useOpenPage({
        webLink: "https://www.facebook.com/michelle.lei.50",
        iOSLink: "fb://profile/1363827123",
      });
    };
    const toInstagram = () => {
      useOpenPage({
        webLink: "https://www.instagram.com/michelle_nolastname",
        iOSLink: "instagram://user?username=michelle_nolastname",
      });
    };
    const toYoutube = () => {
      window.open(
        "https://www.youtube.com/channel/UCWcPMDgTL7Lddrh8WH5W0sg",
        "_blank"
      );
    };
    const toGithub = () => {
      window.open("https://github.com/leimichelle", "_blank");
    };
    const toLinkedIn = () => {
      window.open("https://www.linkedin.com/in/michelle-lei-01", "_blank");
    };

    const toClubhouse = () => {
      window.open("https://www.joinclubhouse.com/@michelleleiii", "_blank");
    };

    const slideIn = () => {
      setTimeout(() => {
        startAnimation.value = true;
      }, 1500);
    };

    return {
      startAnimation,
      toFacebook,
      toInstagram,
      toYoutube,
      toGithub,
      toLinkedIn,
      toClubhouse,
      VueLogo,
      SvelteLogo,
      ashtangiBackgroundSrc,
      programmerBackgroundSrc,
      ashtangiPreviewBackgroundSrc,
      programmerPreviewBackgroundSrc,
      ashtangiWebpBackgroundSrc,
      programmerWebpBackgroundSrc,
      LazyBackgroundLoad,
      slideIn,
    };
  },
});
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Merienda");
@import url("https://fonts.googleapis.com/css?family=Quattrocento");
.photo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-left: 5%;
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 30%,
    rgba(255, 255, 255, 0.65) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  font-size: 4.5vw;
  font-weight: 600;
  &--loading,
  &--loaded {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transition: backdrop-filter 3s;
  }
  &--loading {
    backdrop-filter: blur(3px);
  }
  &--loaded {
    backdrop-filter: blur(0);
  }
}

.ashtangi-container,
.programmer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 0;
  height: 66.7vw;
  width: 100%;
  font-size: 4.5vw;
  font-weight: 600;
  padding-left: 5%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 30%,
    rgba(255, 255, 255, 0.65) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  @include sm-and-up {
    display: none;
  }
}

.ashtangi-container {
  font-family: "Merienda";
  padding-bottom: 3%;
  justify-content: flex-end;
}

.programmer-container {
  font-family: "Quattrocento";
  padding-top: 3%;
  justify-content: flex-start;
}

.ashtangi-title {
  margin-top: 4px;
}

.slide-enter-active,
.slide-leave-active {
  animation: slide-in-animation 1.5s ease;
}

@keyframes slide-in-animation {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

.icon {
  font-size: 8vw;
  margin: 0 8px;
  cursor: pointer;
  @include sm-and-up {
    font-size: 48px;
  }
}

$skillIconSize: 10vw;

.react,
.typescript,
.graphql {
  font-size: $skillIconSize;
  @include sm_and_up {
    font-size: 64px;
  }
}

.vuejs {
  width: calc((#{$skillIconSize} - 1vw) * 256 / 221);
  height: auto;
}

.svelte {
  width: calc((#{$skillIconSize} - 1vw) * 5 / 6);
  height: auto;
}

.typescript {
  color: #0078cf;
}

.graphql {
  color: rgb(225, 0, 152);
}

.react {
  color: #61dafb;
}

.plus {
  font-size: 8vw;
  @include sm_and_up {
    font-size: 51.2px;
  }
}

.mobile {
  @include sm-and-up {
    display: none;
  }
}

@mixin desktop-container {
  width: 100%;
  padding: 5vh 5vw;
  max-width: 1000px;
  box-sizing: content-box;
}

.desktop {
  @include desktop-container;
  display: none;
  @include sm-and-up {
    display: initial;
  }
  .title {
    font-size: 4vw;
    color: #636363;
  }
  .title--ashtangi {
    font-family: "Merienda";
  }

  .title--programmer {
    font-family: "Quattrocento";
  }

  .ashtangi-text-container {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.gradient-background {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.5) 20%,
    rgba(255, 255, 255, 0.21) 100%
  );
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.desktop__about {
  display: none;
  @include sm-and-up {
    display: initial;
  }
}

.blog-feed {
  max-width: 100%;
  padding: 16px;
  margin-bottom: 20vh;

  @include xs-and-up {
    padding: 16px;
  }

  @include md-and-up {
    @include desktop-container;
  }
}
</style>
