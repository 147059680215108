<template>
  <div>
    <h2 class="section-title">Latest Blog</h2>
    <h3 class="full-blog">
      <a href="https://blog.michellevue.com" class="full-blog__link">
        Full Blog
        <arrow-right />
      </a>
    </h3>
    <article class="latest-blog">
      <h4 class="latest-blog__title">{{ latestPost && latestPost.title }}</h4>
      <time class="latest-blog__pubDate">
        {{ latestPost && latestPost.pubDate }}
      </time>
      <div
        class="latest-blog__body"
        v-html="latestPost && latestPost.postHTML"
      ></div>
    </article>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, onMounted } from "vue";
import { BlogRssParser, BlogPost } from "@/lib/blog-rss-parser";
import { ArrowRight } from "mdue";
export default defineComponent({
  name: "BlogRss",
  components: {
    ArrowRight,
  },
  setup() {
    const latestPost = ref() as Ref<BlogPost>;
    const parser = new BlogRssParser(
      "https://asia-northeast1-michellevue-c51d2.cloudfunctions.net/getLatestBlogPosts"
    );
    onMounted(async () => {
      const posts = await parser.getPosts();
      if (posts.length > 0) {
        latestPost.value = posts[0];
      }
    });
    return { latestPost };
  },
});
</script>

<style lang="scss" scoped>
.feedwidget {
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 700px;
  @include sm-and-up {
    height: 500px;
  }
  background-color: transparent;

  &__iframe {
    border: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 500px;
  }
}

.full-blog {
  display: flex;
  justify-content: center;
  padding: 24px;
  @include sm-and-up {
    padding: 12px;
  }
  &__link {
    display: flex;
    justify-self: center;
    font-size: 18px;
    line-height: 18px;
    padding: 6px;
    border: 1.5px solid #000;
    border-radius: 8px;
    &:hover,
    &:active {
      background-color: #7c7c7c;
      color: white;
      border-color: transparent;
    }
  }
}

.latest-blog {
  display: flex;
  flex-direction: column;
  text-align: justify;

  &__title {
    font-size: 32px;
    margin-right: auto;
    text-align: left;
  }

  &__pubDate {
    margin-right: auto;
  }

  &__body {
    color: black;
  }
}
</style>

<style lang="scss">
.latest-blog {
  .separator {
    display: flex;
    justify-content: center;
  }
}
</style>
