<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "App"
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto");
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #7c7c7c;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#html,
body {
  height: 100%;
  background: rgb(171, 171, 171);
  background: linear-gradient(
    180deg,
    rgba(171, 171, 171, 1) 0%,
    rgba(255, 241, 179, 1) 70%,
    rgb(249 177 21) 95%,
    rgb(154 105 0 / 95%) 100%
  );
  @include sm-and-up() {
    min-height: 100vh;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
</style>
