
import { defineComponent, ref, Ref, onMounted } from "vue";
import { BlogRssParser, BlogPost } from "@/lib/blog-rss-parser";
import { ArrowRight } from "mdue";
export default defineComponent({
  name: "BlogRss",
  components: {
    ArrowRight,
  },
  setup() {
    const latestPost = ref() as Ref<BlogPost>;
    const parser = new BlogRssParser(
      "https://asia-northeast1-michellevue-c51d2.cloudfunctions.net/getLatestBlogPosts"
    );
    onMounted(async () => {
      const posts = await parser.getPosts();
      if (posts.length > 0) {
        latestPost.value = posts[0];
      }
    });
    return { latestPost };
  },
});
