
import { defineComponent } from "vue";
export default defineComponent({
  name: "About",
  props: {
    question: {
      type: String,
      required: true
    },
    answer: {
      type: String,
      required: true
    }
  }
});
